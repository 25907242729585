<template>
  <div>
    <b-overlay :show="loading" rounded="sm" >
    <div class="pricing-free-trial startTrainingHeaderStyle">
      <b-row>
        <b-col xl="12">
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-2" style="margin-left: 10px !important;">
              <b-row>
                <b-col xl="12" cols="12">
                  <b-row>
                    <b-col xl="2" class="mt-2">

                      <b-img fluid :src="require('@/assets/images/logo/logo2.png')" class="pricing-trial-img"
                        alt="svg img" style="height: 120px; width: 120px" />
                    </b-col>
                    <b-col xl="9" class="mt-0">
                      <div class="text-primary" style="margin-top: -10px !important;">
                        MENU DE ADMINISTRACION <b>LAVARCLICK !</b> <span class="text-secondary">Dashboard de la
                          Plataforma</span>
                      </div>
                      <b-form-group class="blog-search">

                        <b-input-group class="input-group-merge">
                          <b-form-input id="search-input" v-model="search_query"
                            placeholder="Filtrar Dashboard x Servicio" />
                          <b-input-group-append class="cursor-pointer" is-text>
                            <feather-icon icon="SearchIcon" />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>


                    </b-col>
                    <b-col xl="1" style="padding-top: 0px !important; font-size: 0.8em !important;">


                    </b-col>
                  </b-row>
                </b-col>
              </b-row>


              <!-- <b-form-group class="blog-search">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="search-input"
              v-model="search_query"
              placeholder="Buscar categoria o servicio ..."
            />
            <b-input-group-append
              class="cursor-pointer"
              is-text
            >
              <feather-icon
                icon="SearchIcon"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group> -->
            </div>

            <!-- images -->
            <div
              style="height: 10% !important; width: 30% !important; margin-top: 30px !important; margin-right: -70px !important;">

              <b-img fluid :src="require('@/assets/images/illustration/33.png')" class="pricing-trial-img" alt="svg img"
                style="height: 160px" />
            </div>

            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- blogs -->
    <!-- <b-row style="margin-top: -70px !important;">
        <b-col cols="2" style="font-size: 15px !important;">
          Filtrar Categorias de Servicios
          </b-col>
          <b-col cols="4">
          <b-form-group class="blog-search">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="search-input"
              v-model="search_query"
              placeholder="Buscar categoria o servicio ..."
            />
            <b-input-group-append
              class="cursor-pointer"
              is-text
            >
              <feather-icon
                icon="SearchIcon"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        </b-col>
      </b-row> -->
    <b-row v-if="flagMapData" style="padding-bottom: 60px;">
      <b-col xl="3">
        <div
          style="text-align: center; width: 100% !important; height: 850px !important; margin-top: -70px; border-style: solid; border-color: #000000; border-width: 0.1em; border-radius: 15px;">
          <h3 style="margin-top: 3%; padding-bottom: 1%;">Reporte de Clientes LavarClick !</h3>
          <MapsAdminDashboard :locate_service_provider="adminDashboardData[0]"
            @getCustomerOrdersList="getCustomerOrdersList" />
            <PolarAreaChart :chartjsData="polarChart" />
        </div>
      </b-col>

      <b-col xl="9">
        <b-overlay :show="loading" rounded="sm">
          <b-row>
            <b-col>
              <b-card
                style="width: 100% !important; height: 100% !important; border-style: solid; margin-top: -70px; border-color: #000000; border-width: 0.1em; border-radius: 15px;">
                <b-card-title>
                        Resumen de Estado Servicios
                    </b-card-title>

                <div class="row">
                  
                  <LineChart :chartjsData="lineChart" />
                  <LineChart :chartjsData="lineChart" />
                </div>
              </b-card>
            </b-col>

          </b-row>
          <b-row style="width: 100%; height: 300px;">
            <b-col>
              <b-card
                style="height: 370px !important; width: 100% !important; border-style: solid; margin-top: -50px; border-color: #000000; border-width: 0.1em; border-radius: 15px; overflow-y: auto !important;">
                <b-card-title>
                  Cliente: <span style="font-weight: bold; font-size: 16px;">{{ selectedCustomer.customer.full_name }}
                  </span> Total Ordenes: <span
                    style="font-weight: bold; font-size: 16px;">{{ selectedCustomer.orders.length }}</span>
                </b-card-title>
                <!-- <PolarAreaChart :chartjsData="polarChart" /> -->


                <table class="serviceTableStyle" v-if="flagOrderByCustomer">
                  <tr>
                    <th style="width: 20%;">ORDER INFO</th>
                    <th style="width: 40%;">ITEMS DE LA ORDER</th>
                    <th style="width: 10%;">ACCIONES A LA ORDEN</th>
                  </tr>
                  <tr v-for="(customerOrder, indexCus) in selectedCustomer.orders" v-bind:key="indexCus">
                    <td><span>ORDER-000{{ customerOrder.id_order }}</span><br />
                      Estado: <span
                        style="font-weight: bold; font-size: 16px;">{{ getOrderStatus(customerOrder.timeline) }}</span><br />
                      Valor: <span style="font-weight: bold; font-size: 16px;">{{
                        getTotals(customerOrder.logistic.collect) }} COP</span><br />

                      <FeatherIcon icon="ClockIcon" class="text-success" size="20" /> - Cotizacion Creada <span
                        style="font-weight: bold; font-size: 16px;">{{ customerOrder.timeline.created_at ? formatDate(new
                          Date(customerOrder.timeline.created_at)) : '' }}</span><br />
                      <FeatherIcon icon="ClockIcon" class="text-success" size="20" /> - Solicitud Ejecutada
                      {{ customerOrder.timeline.request_date ? formatDate(new Date(customerOrder.timeline.request_date)) :
                        '' }}<br />
                      {{ customerOrder.timeline.assignment_date ? formatDate(new
                        Date(customerOrder.timeline.assignment_date)) : '' }}<br />
                      {{ customerOrder.timeline.accept_date ? formatDate(new Date(customerOrder.timeline.accept_date)) :
                        '' }}<br />
                    </td>
                    <td>
                      <table>
                        <tr>
                          <th style="width: 10%;">COD</th>
                          <th style="width: 20%;">SERVICIO</th>
                          <th style="width: 5%;">CANT</th>
                          <th style="width: 8%;">Vr UNT</th>
                          <th style="width: 5%;">Vr TOTAL</th>
                        </tr>
                        <tr v-for="(item, indexI) in customerOrder.logistic.collect.items" v-bind:key="indexI">
                          <td>
                            {{ item.code }}
                          </td>
                          <td>
                            {{ item.name }}
                          </td>
                          <td>
                            {{ item.quantity }}
                          </td>
                          <td>
                            {{ item.price }}
                          </td>
                          <td>
                            {{ item.quantity * item.price }}
                          </td>
                        </tr>
                        <tr>
                          <th colspan="5">OBSERVACIONES</th>
                        </tr>
                        <tr>
                          <td colspan="5">{{ customerOrder.comment }}</td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <div class="text-nowrap"
                        style="border-top-style: solid !important; border-bottom-style: solid !important; padding-bottom: 15px !important; padding-top: 15px !important;">
                        <!-- <h3>Acciones de la Order</h3> -->
                        Enviar Orden <feather-icon icon="SendIcon" class="cursor-pointer" size="20" />
                        <b-tooltip title="Enviar" class="cursor-pointer"
                          :target="`invoice-row-${customerOrder.id_order}-send-icon`" /><br />

                        Ver Orden <feather-icon icon="EyeIcon" size="20" class="mx-1"
                          @click="describeOrder(customerOrder)" v-b-modal.modal-primary-order />
                        <b-tooltip title="Vista Detallada"
                          :target="`invoice-row-${customerOrder.id_order}-preview-icon`" /><br />

                        <!-- Dropdown -->
                        Estado Orden <b-dropdown variant="link" toggle-class="p-0" no-caret
                          :right="$store.state.appConfig.isRTL">

                          <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                          </template>
                          <b-dropdown-item>
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">Habilitar</span>
                          </b-dropdown-item>
                          <b-dropdown-item :to="{ name: 'apps-invoice-edit', params: { id: customerOrder.id_order } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Editar</span>
                          </b-dropdown-item>
                          <b-dropdown-item>
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Deshabilitar</span>
                          </b-dropdown-item>
                          <!-- <b-dropdown-item>
                <feather-icon icon="CopyIcon" />
                <span class="align-middle ml-50">Duplicate</span>
              </b-dropdown-item> -->
                        </b-dropdown>
                      </div>
                    </td>
                  </tr>
                  <!-- <tr v-for="(serviceItem, indSI) in computedCart" v-bind:key="indSI">
            <td>{{serviceItem.code}}</td><td>{{serviceItem.name}}</td><td>{{serviceItem.quantity}}</td><td></td>
          </tr> -->

                </table>
              </b-card>
            </b-col>
          </b-row>

        </b-overlay>


      </b-col>


      <!-- <b-col xl="3">
        <div
          style="width: 100% !important; height: 550px !important; margin-top: 0px; border-style: solid; border-color: #000000; border-width: 0.1em; border-radius: 15px; text-align: center;">
          <h3>Reporte de Afiliados LavarClick !</h3>
          <MapsAdminDashboard :locate_service_provider="adminDashboardData[1]" />
        </div>
      </b-col>
      <b-col xl="3">
        <b-card
          style="height: 100% !important; border-style: solid; margin-top: 0px; border-color: #000000; border-width: 0.1em; border-radius: 15px;">
          <b-card-title>
            Resumen de Estado Servicios
          </b-card-title>
          <PolarAreaChart :chartjsData="polarChart" />
        </b-card>
      </b-col> -->
    </b-row>
    <!-- <b-row class="dashboardContainerStyle">
      <b-col xl="5">
        <LineChart :chartjsData="lineChart" />
      </b-col>

    </b-row> -->


    <!--/ blogs -->
    <div v-if="flagDescribeOrder">

      <OrderDescriptor :order="computedSelectedOrder" @closeOrderDescriptor="closeOrderDescriptor" />
    </div>
  </b-overlay>
  </div>
</template>
  
<script>
import {
  BRow, BOverlay, BTooltip, BCol, BCard, BFormInput, BButton, BDropdownItem, BDropdown, BCardText, BCardTitle, BMedia, BAvatar, VBModal, BModal, BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination,
} from 'bootstrap-vue';
import BCardCode from '@core/components/b-card-code';
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import ECommerce from '../../ecommerce/ECommerce.ts';
import Ripple from 'vue-ripple-directive'
import { codeThemes } from './code';
import LineChart from '../charts/LineChart.vue';
import PolarAreaChart from '../charts/PolarAreaChart.vue';
import Category from '@/lavarclick/ecommerce/Category.ts';
import MapsAdminDashboard from '@/lavarclick/components/maps/MapsAdminDashboard.vue';
import OrderDescriptor from '@/lavarclick/components/services/OrderDescriptor.vue';
import { resolve } from 'path';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
// import TimmingManager from '../../ecommerce/TimmingManager.ts';

Ripple.zIndex = 1;

export default {
  components: {
    PolarAreaChart, BDropdownItem, BDropdown, BTooltip, BOverlay,
    OrderDescriptor,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    VBModal,
    BCardCode,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BModal,
    BPagination,
    ContentWithSidebar,
    LineChart,
    MapsAdminDashboard,
    FeatherIcon
  },
  data() {
    return {
      loading: true,
      selectedOrder: {},
      flagDescribeOrder: false,
      selectedCustomer: {
        customer: {

        },
        orders: []
      },
      flagOrderByCustomer: false,
      orderList: [],
      adminDashboardData: [],
      flagMapData: false,
      search_query: '',
      blogList: [],
      blogSidebar: {},
      currentPage: 1,
      perPage: 1,
      rows: 140,
      ecommerce: new ECommerce(),
      rewardsList: [],
      codeThemes,
      editableCategory: {},
      flagEditableCategory: false,
      flagEditableCategoryServices: true,
      indexSelectedCategoryService: 0,
      flagEnableCategoryEdit: false,
      chartColors: {
        primaryColorShade: '#836AF9',
        yellowColor: '#ffe800',
        successColorShade: '#28dac6',
        warningColorShade: '#ffe802',
        warningLightColor: '#FDAC34',
        infoColorShade: '#299AFF',
        greyColor: '#4F5D70',
        blueColor: '#2c9aff',
        blueLightColor: '#84D0FF',
        greyLightColor: '#EDF1F4',
        tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        lineChartPrimary: '#666ee8',
        lineChartDanger: '#ff4961',
        labelColor: '#6e6b7b',
        grid_line_color: 'rgba(200, 200, 200, 0.2)',
      },
      lineChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: 'label',
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: '#FFFFFF',
            titleFontColor: '#000000',
            bodyFontColor: '#000000',
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: 400,
                  fontColor: '#6e6b7b',
                },
                gridLines: {
                  display: true,
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
              },
            ],
          },
          legend: {
            position: 'top',
            align: 'start',
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
        },
        data: {
          labels: ['07/25', 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140], // new TimmingManager().getPastDateListFromNow(15, true), //['07/25', 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140],
          datasets: [
            {
              data: [80, 150, 180, 270, 210, 160, 160, 202, 265, 210, 270, 255, 290, 360, 375],
              label: 'Lavanderia',
              borderColor: '#ad362d',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#ad362d',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#FFFFFF',
              pointHoverBackgroundColor: '#ad362d',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: '#544b4a',
            },
            // {
            //   data: [80, 125, 105, 130, 215, 195, 140, 160, 230, 300, 220, 170, 210, 200, 280],
            //   label: 'Asia',
            //   borderColor: chartColors.lineChartPrimary,
            //   lineTension: 0.5,
            //   pointStyle: 'circle',
            //   backgroundColor: chartColors.lineChartPrimary,
            //   fill: false,
            //   pointRadius: 1,
            //   pointHoverRadius: 5,
            //   pointHoverBorderWidth: 5,
            //   pointBorderColor: 'transparent',
            //   pointHoverBorderColor: '#FFFFFF',
            //   pointHoverBackgroundColor: chartColors.lineChartPrimary,
            //   pointShadowOffsetX: 1,
            //   pointShadowOffsetY: 1,
            //   pointShadowBlur: 5,
            //   pointShadowColor: chartColors.tooltipShadow,
            // },
            {
              data: [80, 99, 82, 90, 115, 115, 74, 75, 130, 155, 125, 90, 140, 130, 180],
              label: 'Limpieza Hogar',
              borderColor: '#f2be6b',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#f2be6b',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#FFFFFF',
              pointHoverBackgroundColor: '#f2be6b',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: '#544b4a',
            },
          ],
        },
      },
      polarChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: true,
            position: 'top',
            labels: {
              usePointStyle: true,
              padding: 5,
              boxWidth: 100,
              fontColor: '#6e6b7b',
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: '#000000',
            titleFontColor: '#FFFFFF',
            bodyFontColor: '#FFFFFF',
          },
          scale: {
            r: { pointLabels: { display: true, centerPointLabels: true } },
            scaleShowLine: true,
            scaleLineWidth: 1,
            ticks: {
              display: false,
              fontColor: '#6e6b7b',
            },
            reverse: false,
            gridLines: {
              display: false,
            },
          },
          animation: {
            animateRotate: false,
          },
        },
        data: {
          labels: ['Completado', 'En Proceso', 'Pendiente'],
          datasets: [
            {
              label: 'Population (millions)',
              backgroundColor: [{
                successColorShade: '#28dac6',
                warningColorShade: '#ffe802',
                warningLightColor: '#FDAC34',
                infoColorShade: '#299AFF',
                greyColor: '#EDF1F4',
                successColorShade: '#28dac6',
              }],
              data: [19, 17.5, 30],
              borderWidth: 0,
            },
          ],
        },
      },
      //   {
      //     id: 1,
      //     img: require('@/assets/images/slider/02.jpg'),
      //     title: 'Servicios de Laundry',
      //     avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
      //     userFullName: 'Validez',
      //     blogPosted: 'Válido desde 2022-07-15 hasta 2022-12-31',
      //     tags: ['Alimentacion', 'Pasteleria', 'Cumpleaños'],
      //     excerpt: 'Torta Especial 100 CarboPuntos, Torta Natural 75 CarboPuntos',
      //     comment: 76,
      //   },
      //   {
      //     id: 2,
      //     img: require('@/assets/images/slider/06.jpg'),
      //     title: 'Limpieza Industrial',
      //     avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
      //     userFullName: 'Validez',
      //     blogPosted: 'Válido desde 2022-07-15 hasta 2022-12-31',
      //     tags: ['S-01 Laundry x Prendas', 'S-02 Laundry x Peso'],
      //     excerpt: 'Recibe este Combo con 150 CarboPuntos',
      //     comment: 2100,
      //   },
      //   {
      //     id: 3,
      //     img: require('@/assets/images/slider/04.jpg'),
      //     title: 'Limpieza del Hogar',
      //     avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'),
      //     userFullName: 'Claudia Neal',
      //     blogPosted: 'Jan 10, 2020',
      //     tags: ['Familia', 'Dia en Familia', 'Padres'],
      //     excerpt: 'Adquiere un Pase Dorado por 165 CarboPuntos',
      //     comment: 243,
      //   },
      //   {
      //     id: 4,
      //     img: require('@/assets/images/slider/03.jpg'),
      //     title: 'Withhold a Compelling Piece of Information',
      //     avatar: require('@/assets/images/portrait/small/avatar-s-14.jpg'),
      //     userFullName: 'Fred Boone',
      //     blogPosted: 'Jan 10, 2020',
      //     tags: ['Video'],
      //     excerpt: 'Croissant apple pie lollipop gingerbread. Cookie jujubes chocolate cake icing cheesecake.',
      //     comment: 10,
      //   },
      //   {
      //     id: 5,
      //     img: require('@/assets/images/slider/09.jpg'),
      //     title: 'Unadvertised Bonus Opening: Share a Quote',
      //     avatar: require('@/assets/images/portrait/small/avatar-s-13.jpg'),
      //     userFullName: 'Billy French',
      //     blogPosted: 'Jan 10, 2020',
      //     tags: ['Quote', 'Fashion'],
      //     excerpt: 'Muffin liquorice candy soufflé bear claw apple pie icing halvah. Pie marshmallow jelly.',
      //     comment: 319,
      //   },
      //   {
      //     id: 6,
      //     img: require('@/assets/images/slider/10.jpg'),
      //     title: 'Ships at a distance have Every Man’s Wish on Board',
      //     avatar: require('@/assets/images/portrait/small/avatar-s-13.jpg'),
      //     userFullName: 'Helena Hunt',
      //     blogPosted: 'Jan 10, 2020',
      //     tags: ['Fashion', 'Video'],
      //     excerpt: 'A little personality goes a long way, especially on a business blog. So don’t be afraid to let loose.',
      //     comment: 1500,
      //   },

      // ]
    }
  },
  sockets: {
    connect: function () {
        console.log('socket connected')
    },
    socketConnectionResponse: function (data) {
        console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    }
  },
  created() {
    this.$http.get('/blog/list/data').then(res => { this.blogList = res.data })
    this.$http.get('/blog/list/data/sidebar').then(res => { this.blogSidebar = res.data })
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);

    this.ecommerce.getECommerceProvider({

      get_all: false,
      get_active: true,
      get_providers_by_city: 149,
      id_employee: 0

    }).then((providersList) => {
      
      console.log('net2 ', providersList);

      this.ecommerce.getECommerceCustomer({

        get_all: false,
        get_active: true,
        get_customers_by_city: 149,
        id_employee: 0

      }).then((customersList) => {


        this.adminDashboardData.push({
          customers: customersList,
          mapId: 'MapCustomers',
          key: 'customers'
        });
        this.adminDashboardData.push({
          providers: providersList,
          mapId: 'MapProviders',
          key: 'providers'
        });
        this.flagMapData = true;
        this.loading = false;

      });
    });
  },
  computed: {
    computedSelectedOrder: {
      get: function () { return this.selectedOrder; },
      set: function (nOrder) {
        this.selectedOrder = nOrder;
      }
    }
  },
  methods: {

    getOrderStatus(timeline) {

      let status = '';

      if (timeline.created_at) {
        status = 'Solicitud Creada';

      } else if (timeline.request_at) {
        status = 'Solicitud Ejecutada';
      }

      return status;
    },

    getTotals(collect) {
      console.log('ok');
      let totalB = 0;
      new Promise((resolve, reject) => {

        collect.items.forEach((item, indexIt) => {
          totalB += item.price * item.quantity;
          if (indexIt === (collect.items.length - 1)) {
            resolve(true);
          }
        });
      }).then((result) => {

        console.log('termi');

      });
      return totalB;
    },

    formatDate(date) {

      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes();

    },

    closeOrderDescriptor(eventx) {


    },

    getCustomerOrdersList(customer) {

      this.loading = true;
      this.orderList = [];
      this.flagOrderByCustomer = false;

      console.log({
        get_all: true,
        get_order_id: 0,
        get_quotes: false,
        get_customer_id: customer.id_customer
      });

      this.ecommerce.getECommerceOrders({
        get_all: true,
        get_order_id: 0,
        get_quotes: false,
        get_customer_id: customer.id_customer
      }).then((categoriesList) => {

        console.log('captu ', categoriesList);

        if (categoriesList.length === 0) {

          this.loading = false;

        } else {

          categoriesList.forEach((order) => {

            order.order.id_order = order.id_order;
            order.order.timeline = order.timeline;
            this.orderList.push(order.order);

            if (this.orderList.length === categoriesList.length) {
              this.selectedCustomer = {
                customer: customer,
                orders: this.orderList
              };
              console.log('parapara ss', {
                customer: customer,
                orders: this.orderList
              });
              this.loading = false;
              this.flagOrderByCustomer = true;
            }
          });

        }
      });
      // console.log('econmia ', customer);
    },

    startEditCategory(property, eventx) {
      this.editableCategory['' + property] = '' + eventx.srcElement.innerHTML;
      console.log('startEditCategory ', this.editableCategory);

    },

    createCategory(newCategory) {

      console.log('investi ', newCategory);
      this.ecommerce.createCategory(newCategory).then((newCategoryResult) => {

        console.log('llegue ', newCategoryResult);

      });
    },

    editCategoryHidden(eventx) {

      this.flagEnableCategoryEdit = false;
      this.flagEditableCategory = false;
      this.flagEditableCategoryServices = false;

    },

    enableCategoryEdit(eventx) {

      this.flagEnableCategoryEdit = !this.flagEnableCategoryEdit;

    },

    hideCategoryService() {

      this.flagEditableCategoryServices = true;

    },
    showCategoryService(service, index) {

      this.indexSelectedCategoryService = index;
      console.log('milo ', service);
      this.ecommerce.getCategoryServicesItems(service.id_service).then((serviceItems) => {

        this.editableCategory.services[this.indexSelectedCategoryService].catalog = serviceItems;
        this.flagEditableCategoryServices = false;

      });

    },
    setEditableCategory(editableCategory) {
      this.editableCategory = editableCategory;
      this.editableCategory.services = [];

      this.ecommerce.getCategoryServices(editableCategory.id_category).then((servicesList) => {


        this.editableCategory.services = servicesList;
        this.flagEditableCategory = true;
        this.flagEditableCategoryServices = true;
        // document.getElementById('modal-primary').addEventListener("hidden.bs.modal", alert('relac'));

      });

    },
    describeOrder(order) {
      this.computedSelectedOrder = order;
      this.flagDescribeOrder = true;
    },
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
@import '@core/scss/vue/pages/page-pricing.scss';

@font-face {
  font-family: 'FredokaOne';
  src: url('/assets/fonts/FredokaOne-Regular.ttf');
}

@font-face {
  font-family: 'MavenPro';
  src: url('/assets/fonts/MavenPro-VariableFont_wght.ttf');
}

.startTrainingHeaderStyle {
  height: 120px !important;
  font-size: 20px !important;
  text-align: center !important;
  margin-top: 0px !important;
  width: 100% !important;
  font-family: 'MavenPro' !important;
  z-index: 20 !important;
}

.editCategoryButtonStyle {
  position: absolute !important;
  margin-left: 80% !important;
}

.serviceTableStyle {
  margin-left: 0px !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-collapse: collapse;
  font-family: 'MavenPro' !important;
  border-radius: 10px !important;
  width: 100% !important;
  margin-top: -10px !important;
  overflow-y: auto !important;
  height: 100% !important;
}


.serviceTableStyle th {
  border: #4e95f4 0px solid;
  font-size: 14px !important;
  color: #ffffff !important;
  background-color: #6d36ac !important;
  text-align: center !important;
  font-family: 'MavenPro' !important;
}

.serviceTableStyle td {
  border: #4e95f4 0px solid;
  text-align: justify !important;
  font-family: 'MavenPro' !important;

}

/* provide some minimal visual accomodation for IE8 and below */
.serviceTableStyle tr {
  background: #b8d1f3;
}

/*  Define the background color for all the ODD background rows  */
.serviceTableStyle tr:nth-child(odd) {
  background: #f2edf7;
}

/*  Define the background color for all the EVEN background rows  */
.serviceTableStyle tr:nth-child(even) {
  background: #f2faf0;
}

.dashboardContainerStyle {
  margin-top: -60px !important;
}
</style>
  